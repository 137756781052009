import {MESSAGE_RECEIVE, MESSAGE_SEND} from '@glark-newco/game-library/assetKeys';

export const LOBBY_BACKGROUND = 'lobby_bg';
export const GAME_BACKGROUND = 'game_bg';
export const PLAY_BACKGROUND = 'play_bg';
export const TIME_PANEL = 'time_panel';
export const TILE_BACKGROUND = 'single_tile';
export const OVERLAY = 'overlay';
export const AVATAR_OPTIONS_PANEL = 'avatar_options_panel';
export const SYMBOLS = 'tiles';
export const FULLSCREEN = 'fullscreen';
export const EXIT_FULLSCREEN = 'exit_fullscreen';
export const CLOSE_ICON = 'close_icon';
export const CROSS = 'cross';
export const TICK = 'tick';
export const CONFETTI = 'confetti';
export const GAMEOVER = 'gameover';
export const PARTYPOPPER = 'partypopper';
export const STREAMERS = 'streamers';
export const CONGRATULATIONS = 'congratulations';
export const AVATAR_OPTIONS = 'avatar_options';
export const AVATAR_TRIMMED_OPTIONS = 'avatar_trimmed_options';
export const PROGRESS_GRADIENT = 'progress_gradient';

export const APPLAUSE = 'applause';
export const GAMEOVER_SOUND = 'gameover_sound';
export const CLICK = 'click';
export const DRAG = 'drag';
export const FLIP = 'flip';
export const INCORRECT = 'incorrect';
export const PLACE = 'place';
export const CRACKLING_FIRE = 'crackling_fire';
export const TENSION = 'tension';

export const assetManifest = {
    bundles: [
        {
            name: 'load-screen',
            assets: [
                {name: LOBBY_BACKGROUND, srcs: '../images/Signlines lobby background.png'},
                {name: GAME_BACKGROUND, srcs: '../images/Signlines game background.png'},
                {name: PLAY_BACKGROUND, srcs: '../images/Signlines play background.png'},
                {name: TIME_PANEL, srcs: '../images/Signlines time panel.png'},
                {name: TILE_BACKGROUND, srcs: '../images/Signlines single tile.png'},
                {name: OVERLAY, srcs: '../images/Signlines overlay.png'},
                {name: AVATAR_OPTIONS_PANEL, srcs: '../images/Signlines-avatar-options-panel.png'},
                {name: SYMBOLS, srcs: '../images/Signlines tiles.json'},
                {name: FULLSCREEN, srcs: '../images/fullscreen-icon.svg'},
                {name: EXIT_FULLSCREEN, srcs: '../images/exit-fullscreen.svg'},
                {name: CLOSE_ICON, srcs: '../images/close icon.svg'},
                {name: CROSS, srcs: '../images/cross.svg'},
                {name: TICK, srcs: '../images/tick.svg'},
                {name: CONFETTI, srcs: '../images/confetti.svg'},
                {name: GAMEOVER, srcs: '../images/gameover.svg'},
                {name: PARTYPOPPER, srcs: '../images/partypopper.svg'},
                {name: STREAMERS, srcs: '../images/streamers.svg'},
                {name: CONGRATULATIONS, srcs: '../images/congratulations.svg'},
                {name: AVATAR_OPTIONS, srcs: '../images/avatar_options.json'},
                {name: AVATAR_TRIMMED_OPTIONS, srcs: '../images/avatar_trimmed_options.json'},
                {name: PROGRESS_GRADIENT, srcs: '../images/progress_gradient.svg'},
                {name: APPLAUSE, srcs: '../sounds/applause.mp3'},
                {name: GAMEOVER_SOUND, srcs: '../sounds/game-over.mp3'},
                {name: CLICK, srcs: '../sounds/click.mp3'},
                {name: DRAG, srcs: '../sounds/drag.mp3'},
                {name: FLIP, srcs: '../sounds/flip.mp3'},
                {name: INCORRECT, srcs: '../sounds/incorrect.mp3'},
                {name: PLACE, srcs: '../sounds/place.mp3'},
                {name: TENSION, srcs: '../sounds/tension.mp3'},
                {name: CRACKLING_FIRE, srcs: '../sounds/crackling-fire.mp3'},
                {name: MESSAGE_SEND, srcs: '../sounds/message-send.mp3'},
                {name: MESSAGE_RECEIVE, srcs: '../sounds/message-receive.mp3'},
            ],
        },
    ],
};