import {OverlaySupplier, Scene} from '@glark-newco/game-library';
import {GamePlayOverlay} from './GamePlayOverlay';
import {LobbyOverlay} from './LobbyOverlay';
import {ReviewOverlay} from './ReviewOverlay';

export const signlinesOverlaySupplier: OverlaySupplier = (scene: Scene) => {
    switch (scene) {
        case 'LOBBY': return <LobbyOverlay/>;
        case 'PLAY': return <GamePlayOverlay/>;
        case 'REVIEW': return <ReviewOverlay/>;
        default: <></>;
    }
};
