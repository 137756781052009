import {useServerTime} from '@glark-newco/game-library';
import dayjs from 'dayjs';
import {useMemo} from 'react';
import {useCrunchTime} from './useCrunchTime';
import {useGameCompletion} from './useSignlinesBoard';

export const useGameOver = (): {
    isGameOver: boolean,
} => {
    const {isCrunchTime, crunchTimeStartTime} = useCrunchTime();
    const {isComplete} = useGameCompletion();
    const serverTime = useServerTime();

    const gameOverTime = useMemo(() => {
        if (!crunchTimeStartTime) { return null; }
        const lastTileDelay = 29 * 350; // ms
        return dayjs(crunchTimeStartTime)
            .add(lastTileDelay, 'milliseconds')
            .add(750, 'milliseconds')
            .add(isComplete ? 0 : 20, 'seconds');
    }, [crunchTimeStartTime]);

    const isGameOver = useMemo(() => {
        if (!serverTime) { return false; }
        return isCrunchTime && serverTime.currentTime.isAfter(gameOverTime);
    }, [serverTime, gameOverTime, isCrunchTime, isComplete]);

    return {isGameOver};
};