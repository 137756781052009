import {Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {Scene} from './Scene';
import {GAME_BACKGROUND} from '../assetManifest';

export function Review() {

    return (
        <Scene>
            <Sprite texture={Assets.get(GAME_BACKGROUND)}/>
        </Scene>
    );
}