import {cols, getAdjacentTiles, rows} from './GameBoardUtils';
import {TileId} from '../hooks/useSignlinesBoard';

export type PlayerHand = TileId[];

export function shuffle<T>(array: T[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export function dealRandom(hands: number): PlayerHand[] {
    const tiles = new Array(cols.length * rows.length).fill(1).map((_, cellIndex) => {
        const colIndex = cellIndex % cols.length;
        return cols[colIndex] + String(Math.floor(cellIndex / cols.length));
    });
    shuffle(tiles);

    const assignedTiles: PlayerHand[] = new Array<PlayerHand>(hands);
    tiles.forEach((tile, index) => {
        assignedTiles[index % hands] = [
            ...assignedTiles[index % hands] ?? [],
            tile,
        ];
    });
    return assignedTiles;
}

export function dealTeamvineStrategy(hands: number): PlayerHand[] {
    const assignedTiles: PlayerHand[] = new Array<PlayerHand>(hands);
    const largestHand = Math.ceil((cols.length * rows.length) / hands);
    const corners: TileId[] = [];
    const edges: TileId[] = [];
    const middle: TileId[] = [];

    const splitIntoWholeNumbers = (number: number, parts: number): number[] => {
        const base = Math.floor(number / parts);
        const result = (Array(parts).fill(base));
        let remaining = (number % parts);
        let index = 0;
        while (remaining > 0) {
            result[index] += 1;
            remaining -= 1;
            index += 1;
        }
        return (result as number[]);
    };

    // Create array of tiles with corners and edges first
    rows.forEach((row, r) => {
        cols.forEach((col, c) => {
            if ((r === 0 || r === rows.length - 1) && (c === 0 || c === cols.length - 1)) {
                corners.push(col + row);
            } else if (r === 0 || r === rows.length - 1 || c === 0 || c === cols.length - 1) {
                edges.push(col + row);
            } else {
                middle.push(col + row);
            }
        });
    });

    // Avoid players receiving adjacent tiles
    const pushAdjacentTilesToBack = (arr: TileId[]) => {
        for (let i = 0; i < arr.length; i++) {
            const aj = getAdjacentTiles(arr[i]);
            const nextGroup = arr.slice(i, largestHand + i);

            for (let j = 0; j < nextGroup.length; j++) {
                if (aj.includes(arr[i + j])) {
                    arr.push(arr.splice(i + j, 1)[0]);
                }
            }
        }
    };
    pushAdjacentTilesToBack(edges);
    pushAdjacentTilesToBack(middle);

    // Fill first hands tiles with corners and middle, then rest with edges then middle
    const tiles: TileId[] = [...corners, ...(middle.slice(0, largestHand - 4)), ...edges, ...(middle.slice(largestHand - 4))];

    // Deals tiles to each hand
    const tilesPerPlayer = splitIntoWholeNumbers(tiles.length, hands);
    tilesPerPlayer.forEach((playerTileCount, i) => {
        assignedTiles[i] = tiles.splice(0, playerTileCount);
    });

    shuffle(assignedTiles);
    return assignedTiles;
}
