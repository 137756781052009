import {PixiRef, Sprite} from '@pixi/react';
import {Assets, Texture} from 'pixi.js';
import {RefObject, useEffect, useRef, useState} from 'react';

export type ISprite = PixiRef<typeof Sprite>;

interface FlipAnimationProps {
    spriteRef: RefObject<ISprite> | null,
    prevTextureName: string,
    nextTextureName: string,
    tileFaceIsVisible: boolean,
}

export function useFlipAnimation({spriteRef, prevTextureName, nextTextureName, tileFaceIsVisible}: FlipAnimationProps) {
    const [childrenVisible, setChildrenVisible] = useState(true);
    const startTimeRef = useRef<number>(0);
    const textureRef = useRef<string>(prevTextureName);
    const animationTimeMs = 350;

    useEffect(() => {
        if (!spriteRef || !spriteRef.current) return;
        let textureChangedRef = false;

        // stops animation when last texture was the samee
        if (textureRef.current === nextTextureName) { return; }

        if (spriteRef.current) {
            spriteRef.current.texture = Assets.get(prevTextureName);
        }

        startTimeRef.current = performance.now();
        setChildrenVisible(false);

        const animate = (time: number) => {
            const elapsed = time - startTimeRef.current;
            const progress = Math.min(elapsed / animationTimeMs, 1);

            const scaleX = Math.abs(Math.cos(progress * Math.PI));
            const scaleY = 1 + 0.5 * Math.sin(progress * Math.PI);

            spriteRef.current!.scale = {x: scaleX, y: scaleY};

            if (progress >= 0.5 && !textureChangedRef) {
                textureChangedRef = true;
                // eslint-disable-next-line
                spriteRef.current!.texture = Assets.get<Texture>(nextTextureName);
            }

            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                spriteRef.current!.scale = {x: 1, y: 1};
                setChildrenVisible(true);
                textureRef.current = nextTextureName;
            }
        };

        requestAnimationFrame(animate);
    }, [tileFaceIsVisible]);

    return {childrenVisible};
}