import {NineSlicePlane, Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {Scene} from './Scene';
import {GAME_BACKGROUND, OVERLAY} from '../assetManifest';

export function Register() {

    return (
        <Scene>
            <Sprite texture={Assets.get(GAME_BACKGROUND)}/>
            <NineSlicePlane
                texture={Assets.get(OVERLAY)}
                leftWidth={15}
                topHeight={15}
                rightWidth={50}
                bottomHeight={15}
                width={725}
                height={400}
                x={610}
                y={345}
            />
        </Scene>
    );
}