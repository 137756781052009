import {Container, Graphics, PixiRef, Sprite} from '@pixi/react';
import {Sound} from '@pixi/sound';
import {Assets} from 'pixi.js';
import {useEffect, useRef} from 'react';
import {GAMEOVER, GAMEOVER_SOUND} from '../assetManifest';
import {play, radians} from '../utils/AnimationUtils';

export type ISprite = PixiRef<typeof Sprite>;
export type IGraphics = PixiRef<typeof Graphics>;

export function GameOverAnimation() {
    const gameOverSpriteRef = useRef<ISprite>(null);

    const startTimeRef = useRef<number>(0);
    const animationTimeMs = 4000;

    useEffect(() => {
        startTimeRef.current = performance.now();
        
        const animate = (time: number) => {
            const elapsed = time - startTimeRef.current;
            const progress = Math.min(elapsed / animationTimeMs, 1);

            if (gameOverSpriteRef.current) {
                play(0, .4, progress, (value) => gameOverSpriteRef.current!.scale = {x: value, y: value}, 0, 2.25);
                play(.75, .875, progress, (value) => gameOverSpriteRef.current!.y = value, 0, 600);
                play(.875, 1, progress, (value) => gameOverSpriteRef.current!.y = value, 600, 1400);
                play(.75, 1, progress, (value) => gameOverSpriteRef.current!.rotation = value, radians(0), radians(50));
                play(.95, 1, progress, (value) => gameOverSpriteRef.current!.alpha = value, 1, 0);
                gameOverSpriteRef.current.visible = true;
            }

            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                if (gameOverSpriteRef.current) {
                    gameOverSpriteRef.current.visible = false;
                }
            }
        };

        requestAnimationFrame(animate);
    }, []);

    useEffect(() => {
        void Assets.get<Sound>(GAMEOVER_SOUND).play();
    }, []);

    return (
        <Container
            x={(1920 / 2)}
            y={(1080 / 2) - 125}
            zIndex={3}
        >
            <Sprite
                ref={gameOverSpriteRef}
                texture={Assets.get(GAMEOVER)}
                visible={false}
                scale={0}
                x={0} y={0} anchor={0.5}
            />
        </Container>
    );
}
