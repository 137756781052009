import {withServerTime} from '@glark-newco/game-library';
import {Texture} from '@pixi/core';
import {Graphics as PixiGraphics} from '@pixi/graphics';
import {Container, Graphics, Sprite, Text} from '@pixi/react';
import {Dayjs} from 'dayjs';
import {Assets} from 'pixi.js';
import {PropsWithChildren, useCallback, useMemo} from 'react';
import {PROGRESS_GRADIENT, TIME_PANEL} from '../assetManifest';
import {textStyle} from '../constants';
import {useGameEnd} from '../state/signlinesGameState';

const THIRTY_MINUTES_IN_MS = 1000 * 60 * 30;

function NumericTime({currentTime}: {currentTime: Dayjs}) {
    return (
        <Text
            x={155} y={83}
            text={currentTime.valueOf() > 0 ? currentTime.format('mm:ss') : '00:00'}
            style={textStyle({
                fill: ['#ffffff'],
                stroke: '#ffffff',
            })}
        />
    );
}

function CountdownGauge({remainingMs}: {remainingMs: number}) {
    const gradientFillOptions = useMemo(() => ({
        texture: Assets.get<Texture>(PROGRESS_GRADIENT),
    }), []);

    const draw = useCallback((hpBar: PixiGraphics, progress: number) => {
        hpBar.clear();
        hpBar.beginTextureFill(gradientFillOptions);
        hpBar.drawRoundedRect(
            124, 134, // x, y
            5 + (195 * progress), 20, // w, h
            10, // radius
        );
        hpBar.endFill();
    }, []);

    return <Graphics draw={graphics => draw(graphics, (remainingMs / THIRTY_MINUTES_IN_MS))}/>;
}

const NumericServerTime = withServerTime(({currentTime}) => {
    const endTime = useGameEnd();

    return (
        <>
            <NumericTime currentTime={endTime.subtract(currentTime.valueOf())}/>
            <CountdownGauge remainingMs={endTime.diff(currentTime)}/>
        </>
    );
},
);

interface TimePanelProps extends PropsWithChildren {
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TimePanel(_props: TimePanelProps) {
    return (
        <Container
            x={73} y={13}
            width={386} height={192}
        >
            <Sprite
                texture={Assets.get(TIME_PANEL)}
                width={386} height={192}
                x={0} y={0}
                interactive={true}/>
            <NumericServerTime/>
        </Container>
    );
}