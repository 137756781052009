import {useLoadingState} from '@glark-newco/game-library';
import {Graphics as PixiGraphics} from '@pixi/graphics';
import {Container, Graphics, Text} from '@pixi/react';
import {Assets, ColorSource} from 'pixi.js';
import {useCallback, useEffect, useState} from 'react';
import {Scene} from './Scene';
import {assetManifest} from '../assetManifest';
import {textStyle} from '../constants';


function LoadingBar(props: {x: number, y: number, progress: number, colour: ColorSource}) {

    const draw = useCallback((hpBar: PixiGraphics, progress: number) => {
        hpBar.beginFill(props.colour);
        hpBar.drawPolygon([
            50, 80,
            50 + (400 * progress), 80,
            32 + (400 * progress), 150,
            32, 150,
        ]);
        hpBar.endFill();
    }, []);

    return <Graphics draw={graphics => draw(graphics, props.progress)}/>;
}

export function PreLoader() {
    const {setLoadingState} = useLoadingState();
    const [preloadProgress, updatePreloadProgress] = useState(0);

    useEffect(() => {
        void Assets.init({manifest: assetManifest}).then(() => {
            void Assets.loadBundle(
                assetManifest.bundles.map((b) => b.name),
                (p) => {
                    console.log('Asset loading progress', p);
                    updatePreloadProgress(p);
                },
            ).then(() => {
                setLoadingState('READY');
            });
        });
    }, []);

    return (
        <Scene>
            <Text text="Loading ..." style={textStyle()} x={850} y={300}/>
            <Container x={(1920 / 2) - 250} y={480} scale={5} height={30} width={250} >
                <LoadingBar x={0} y={0} progress={1} colour={'#220000'}/>
                <LoadingBar x={0} y={0} progress={preloadProgress} colour={'#ff0000'}/>
            </Container>
        </Scene>
    );
}