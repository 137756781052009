
import {Player, PlayerBadge, PlayerId, useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import {Box, Button, Checkbox, Flex, Grid, Group, Modal, Radio, Stack, Text, UnstyledButton} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {Notifications} from '@mantine/notifications';
import {IconPlayerPlay} from '@tabler/icons-react';
import {useCallback, useEffect, useState} from 'react';
import styles from './Facilitator.module.css';
import {FacilitatorTileMoving} from './FacilitatorTileMoving';
import {SignlinesAvatarProvider} from './SignlinesAvatar';
import {DealTilesEvent, SignlinesPayload, StartTimerEvent} from '../SignlinesPayloadTypes';
import {DealStrategies, useFacilitatorOptions} from '../state/FacilitatorOptionsAtom';
import {dealRandom, dealTeamvineStrategy, PlayerHand} from '../utils/SignlinesDealUtils';

interface DealOption {
    name: DealStrategies,
    description: string,
    dealer: (hands: number) => PlayerHand[]
}

const dealOptions: DealOption[] = [{
    name: 'Random',
    description: 'Tiles are assigned randomly between players.',
    dealer: (hands: number) => dealRandom(hands),
}, {
    name: 'Teamvine Strategy',
    description: 'Corner tiles are assigned to the smallest number of players. Edge tiles are next assigned to the smallest number of players. Center tiles are distributed amongst remaining players.',
    dealer: (hands: number) => dealTeamvineStrategy(hands),
}];

function PlayerSelectionButton({player}: {player: Player}) {
    const {facilitatorOptions, addSelectedPlayer, removeSelectedPlayer, isPlayerSelected} = useFacilitatorOptions();

    const handlePlayerSelect = useCallback((playerId: PlayerId) => {
        if (isPlayerSelected(playerId)) {
            removeSelectedPlayer(playerId);
        } else {
            addSelectedPlayer(playerId);
        }
    }, [facilitatorOptions]);

    return (
        <UnstyledButton onClick={() => handlePlayerSelect(player.playerId)} data-testid={`select_${player.playerId}`} className={styles.playerSelectionButton}>
            <Box style={{position: 'absolute', top: '50%', left: '1.6rem', transform: 'translate(-50%,-50%)'}}>
                <Checkbox
                    checked={isPlayerSelected(player.playerId)}
                    onChange={() => {}}
                    tabIndex={-1}
                    size="sm"
                    readOnly={true}
                    styles={{input: {cursor: 'pointer'}}}
                    aria-hidden
                />
            </Box>
            <PlayerBadge player={player} avatar={SignlinesAvatarProvider}/>
        </UnstyledButton>
    );
}

function FacilitatorOptionsPanel() {
    const {facilitatorOptions, setDealStrategy} = useFacilitatorOptions();
    const {players} = usePlayers();

    return (<>
        <Box p={'sm'} data-testid='modal-select-player-options-page'>
            <Box><Text style={{textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '1%'}}>Select Players</Text></Box>
            <Grid>
                {players.filter(player => !player.isFacilitator).map(player => (
                    <Grid.Col span={3} key={`facilitator_select_${player.playerId}`}>
                        <PlayerSelectionButton player={player}/>
                    </Grid.Col>
                ))}
            </Grid>
        </Box>
        <Box p={'sm'}>
            <Box><Text style={{textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '1%'}}>Deal Options</Text></Box>
            <Stack>
                {dealOptions.map(option => (
                    <UnstyledButton data-testid={`radio_${option.name}`} key={`deal_option_${option.name}`}
                        onClick={() => setDealStrategy(option.name)}
                        className={styles.dealStrategyButton}>
                        <Radio size="md" mr="xl" readOnly={true} value={option.name}
                            checked={facilitatorOptions.dealStrategy === option.name}/>
                        <div>
                            <Text className={styles.dealOptionName}>{option.name}</Text>
                            <Text>{option.description}</Text>
                        </div>
                    </UnstyledButton>
                ),
                )}
            </Stack>
        </Box>
    </>);
}

export function FacilitatorActions() {
    const {
        facilitatorOptions, 
        addSelectedPlayer,   
        selectedPlayersLength, 
        getTilesAssignedToPlayer, 
        giveSelectedPlayersTiles} = useFacilitatorOptions();
    const [opened, {open, close}] = useDisclosure(false);
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const {players} = usePlayers();
    const [step, setStep] = useState(0);

    // Only when modal is opened active users selected by default
    useEffect(() => {
        const addedPlayers: PlayerId[] = [];
        players.filter(player => player.isActive && !player.isFacilitator).map(player => {
            addedPlayers.push(player.playerId);
        });
        addSelectedPlayer(addedPlayers);
    }, [opened]);

    const openStartModal = useCallback(() => {
        const nonFacilitatorPlayers = players.filter(p => !p.isFacilitator);
        if (nonFacilitatorPlayers.length < 2) {
            Notifications.show({
                color: 'red',
                title: 'Error - cannot deal tiles',
                message: 'At least 2 players are required (excluding facilitators)',
                autoClose: 5000,
            });
            return;
        }
        open();
    }, [players]);

    const dealTiles =  useCallback(() => {
        if (selectedPlayersLength() <= 1) { return; }

        const dealOption = dealOptions.findLast(o => o.name === facilitatorOptions.dealStrategy);
        if (!dealOption)
            throw new Error('Cannot deal without a selected DealOption');

        const dealtTiles: PlayerHand[] = dealOption.dealer(selectedPlayersLength());
        if (dealtTiles?.length !== selectedPlayersLength())
            throw new Error(`Dealer produced an incorrect number of hands.  Expected ${selectedPlayersLength()} hands but ${dealtTiles?.length} were dealt.`);

        const newPlayerTiles = {...facilitatorOptions.selectedPlayersTiles};
        Object.keys(facilitatorOptions.selectedPlayersTiles).forEach((playerId, i) => {
            newPlayerTiles[playerId] = dealtTiles[i];
        });

        giveSelectedPlayersTiles(newPlayerTiles);
        setStep(1);
    }, [facilitatorOptions, players]);


    const startGame =  useCallback(() => {
        if (selectedPlayersLength() <= 1) { return; }
        Object.keys(facilitatorOptions.selectedPlayersTiles).forEach((playerId) => {
            publishPlayerAction({
                type: 'dealTilesEvent',
                playerId,
                tileIds: getTilesAssignedToPlayer(playerId),
            } as DealTilesEvent);
        });
        publishPlayerAction({
            type: 'startTimerEvent',
            duration: 30,
        } as StartTimerEvent);
        close();
    }, [facilitatorOptions, players]);

    return (
        <>
            <Modal 
                opened={opened} onClose={close} size={'75%'} centered withinPortal={false}
                styles={{
                    header: {backgroundImage: `url(${encodeURI('images/Signlines-modal-header.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}, 
                    content: {backgroundImage: `url(${encodeURI('images/Signlines-modal-content.png')})`, overflow: 'hidden', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', boxShadow: 'inset #4f674a 0px 55px 50px 0px'}}}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}>
                <Box p={'sm'}>
                    {/* Modal panel currently being viewed */}
                    {step === 0 
                        ? <FacilitatorOptionsPanel/>
                        : <FacilitatorTileMoving/>
                    }
                    {/* Modal bottom navigation buttons */}
                    <Flex p={'md'} justify={'flex-end'}>
                        <Button role='button' m={'.4%'} name='back' onClick={() => setStep(prev => prev - 1)} disabled={step <= 0}>Back</Button>
                        {step === 0 && <Button role='button' m={'.4%'} data-testid='modal-next-btn' name='next' onClick={dealTiles} disabled={selectedPlayersLength() <= 1}>Next</Button>}
                        {step === 1 && <Button role='button' m={'.4%'} data-testid='modal-deal-btn' name='deal' onClick={startGame}>Deal</Button>}
                    </Flex>
                </Box>
            </Modal>
            <Group 
                data-testid="facilitator-actions"
                style={{
                    width: '20%',
                    position: 'absolute',
                    pointerEvents: 'initial',
                    right: '1rem',
                    top: '2.5rem',
                }}>
                <Button 
                    role='button'
                    name='start game'
                    rightSection={<IconPlayerPlay size={14}/>}
                    radius={'xl'} 
                    variant="gradient" 
                    onClick={openStartModal}
                    gradient={{from: 'blue', to: 'teal', deg: 90}}>
                    Start Game
                </Button>
            </Group>
        </>
    );
}