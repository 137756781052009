import {Container, Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {useMemo} from 'react';
import {generateAvatarOptions} from '../components/SignlinesAvatar';
import {usePlayerAvatars} from '../hooks/usePlayerAvatars';

interface AvatarProps {
    playerId: string,
    x: number,
    y: number,
}

export function Avatar(props: AvatarProps) {
    const {avatars} = usePlayerAvatars();
    const layers = useMemo(() => generateAvatarOptions(avatars[props.playerId]), []);
    return (
        <Container>
            {layers.map((l, i) => (
                <Sprite
                    anchor={.5}
                    key={'pixi-sprite-option-' + i}
                    texture={Assets.get(l)}
                    scale={.1}
                    x={props.x}
                    y={props.y}
                />
            ))}
        </Container>
    );
}