import {useGameServerConnection, useGameState, useLoadingState} from '@glark-newco/game-library';
import {sound} from '@pixi/sound';
import {useEffect, useMemo} from 'react';
import {CracklingFireSoundEffect} from './components/CracklingFireSoundEffect';
import {FullScreenButton} from './pixijs/FullScreenButton';
import {Lobby} from './scenes/Lobby';
import {Play} from './scenes/Play';
import {PreLoader} from './scenes/PreLoader';
import {Register} from './scenes/Register';
import {Review} from './scenes/Review';

export function SignlinesCanvas() {
    const {loadingState} = useLoadingState();
    const {gameState} = useGameState();
    const {connectionReady} = useGameServerConnection();

    const scene = useMemo(()=>{
        if (loadingState === 'PRELOAD')
            return <PreLoader/>;
        else if (!connectionReady)
            return <Register/>;
        else if (gameState.scene === 'LOBBY')
            return <Lobby/>;
        else if (gameState.scene === 'PLAY')
            return <Play/>;
        else
            return <Review/>;
    }, [loadingState, gameState, connectionReady]);

    useEffect(() => {
        sound.disableAutoPause = true;
    }, []);

    return (
        <>
            <CracklingFireSoundEffect scene={gameState.scene} loadingState={loadingState}/>
            {scene}
            {loadingState !== 'PRELOAD' &&
                // Assets are not available until PRELOAD is complete.
                <FullScreenButton position={[1920 - 54, 4]}/>
            }
        </>
    );
}
