import {NineSlicePlane, Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {useRecoilValue} from 'recoil';
import {Scene} from './Scene';
import {AVATAR_OPTIONS_PANEL, LOBBY_BACKGROUND} from '../assetManifest';
import {AvatarEditStateAtom} from '../state/AvatarEditAtom';

export function Lobby() {
    const avatarEdit = useRecoilValue(AvatarEditStateAtom);
    return (
        <Scene>
            <Sprite texture={Assets.get(LOBBY_BACKGROUND)}/>
            {avatarEdit.inEdit
                ? <NineSlicePlane
                    texture={Assets.get(AVATAR_OPTIONS_PANEL)}
                    leftWidth={50}
                    topHeight={50}
                    rightWidth={50}
                    bottomHeight={50}
                    width={500}
                    height={585}
                    x={455}
                    y={280}/>
                : <></>}
            
        </Scene>
    );
}