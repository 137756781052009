import {useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import type {IPointData} from '@pixi/core';
import {FederatedPointerEvent} from '@pixi/events';
import {useApp} from '@pixi/react';
import {Sound} from '@pixi/sound';
import {Assets} from 'pixi.js';
import {useCallback} from 'react';
import {atom, useRecoilCallback, useRecoilState} from 'recoil';
import {CLICK, PLACE} from '../assetManifest';
import {Tile, useGameBoard} from '../hooks/useSignlinesBoard';
import {PlayTileEvent, SignlinesPayload} from '../SignlinesPayloadTypes';
import {colIndexFromPoint, cols, rowIndexFromPoint, rows, TILE_HEIGHT, TILE_WIDTH} from '../utils/GameBoardUtils';


interface TileInteractionType {
    tile?: Tile;
    isDragging: boolean;
    isZoom: boolean;
    offset?: IPointData;
    position: IPointData;
}

const defaultState = {
    isDragging: false,
    isZoom: false,
    position: {x:960, y:540},
};

const tileInteractionAtom = atom<TileInteractionType>({
    key: 'tileInteractionState',
    default: defaultState,
});

export function useTileInteraction() {
    const [state, setTileInteraction] = useRecoilState(tileInteractionAtom);
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const gameBoard = useGameBoard();
    const {localPlayer} = usePlayers();
    const app = useApp();

    const startDragging = useCallback((tile: Tile, offset: IPointData) => {
        const pointer = app.renderer.events.pointer;
        const cursor: IPointData = {x: 0, y: 0};
        app.renderer.events.mapPositionToPoint(cursor, pointer.x, pointer.y);
        setTileInteraction({
            tile, offset, position: cursor,
            isDragging: true, isZoom: false,
        });
        void Assets.get<Sound>(CLICK).play();
    }, [setTileInteraction]);

    const handleDragEnd = useRecoilCallback(({snapshot}) => async (event: FederatedPointerEvent) => {
        const currentState = await snapshot.getPromise(tileInteractionAtom);
        const colIndex = colIndexFromPoint(event.global);
        const rowIndex = rowIndexFromPoint(event.global);
        if (currentState.isDragging && colIndex >= 0 && rowIndex >= 0) {
            const cell = gameBoard.find((c) => c.location == `${cols[colIndex]}${rows[rowIndex]}`);
            if (!cell?.tileId) {
                void Assets.get<Sound>(PLACE).play();
                publishPlayerAction({
                    type: 'playTileEvent',
                    playerId: localPlayer?.playerId,
                    tileId: currentState.tile?.tileId,
                    positionId: cell?.location,
                } as PlayTileEvent);
            }
        }
        setTileInteraction(defaultState);
    }, [setTileInteraction, gameBoard]);

    const handleMouseMove = useRecoilCallback(({snapshot}) => async (event: FederatedPointerEvent) => {
        const currentState = await snapshot.getPromise(tileInteractionAtom);
        if (currentState.isDragging) {
            const cursor: IPointData = {x: 0, y: 0};
            app.renderer.events.mapPositionToPoint(cursor, event.x, event.y);

            const {offset} = currentState;
            const xMin = offset!.x + 400; // Magic number.  Don't impinge the list of players
            const xMax = 1500 - TILE_WIDTH + offset!.x; // Magic number.  Don't impinge the chat
            const yMin = offset!.y;
            const yMax = 1080 - TILE_HEIGHT + offset!.y;
            setTileInteraction({
                ...currentState,
                position: {
                    x: (cursor.x < xMin) ? xMin : (cursor.x > xMax) ? xMax :  cursor.x,
                    y: (cursor.y < yMin) ? yMin : (cursor.y > yMax) ? yMax :  cursor.y,
                },
            });
        }
    }, [setTileInteraction]);

    const setZoom = useRecoilCallback(({snapshot}) => async (tile?: Tile) => {
        const currentState = await snapshot.getPromise(tileInteractionAtom);
        setTileInteraction({
            ...currentState,
            tile,
            isZoom: !!tile,
        });
    }, [setTileInteraction]);

    return {
        isDragging: state.isDragging,
        isZoom: state.isZoom, setZoom,
        tile: state.tile,
        offset: state.offset,
        startDragging, handleDragEnd,
        position: state.position,
        handleMouseMove,
    };
}
