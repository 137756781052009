import {useCoordinationRole, useServerTime} from '@glark-newco/game-library';
import {useEffect} from 'react';
import {useCrunchTime} from '../hooks/useCrunchTime';
import {useGameEnd} from '../state/signlinesGameState';

let initialCrunchTimeEventBeenPublished: boolean = false;

export function CrunchTimeListener() {
    const {publishCrunchTimeEvent, isCrunchTime} = useCrunchTime();
    const {isCoordinator} = useCoordinationRole();
    const serverTime = useServerTime();
    const endTime = useGameEnd();

    useEffect(() => {
        if (isCoordinator && !initialCrunchTimeEventBeenPublished) {
            if (!isCrunchTime && serverTime.currentTime.diff(endTime) > 0) {
                publishCrunchTimeEvent();
                initialCrunchTimeEventBeenPublished = true;
            }
        }
    }, [isCoordinator, serverTime, useGameEnd, isCrunchTime]);

    return null;
}