import {GameChatPanel, PlayersPanel, useGameState, usePlayers} from '@glark-newco/game-library';
import {Stack} from '@mantine/core';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {CrunchTimeButton} from './CrunchTimeButton';
import {EndGameButton} from './EndGameButton';
import {FacilitatorActions} from './FacilitatorActions';
import {SignlinesAvatarProvider} from './SignlinesAvatar';
import {useGameCompletion} from '../hooks/useSignlinesBoard';
import {useCrunchTimeStartTime} from '../state/signlinesGameState';

export function GamePlayOverlay() {
    const {localPlayer} = usePlayers();
    const {gameState} = useGameState();
    const crunchTimeStartTime = useCrunchTimeStartTime();
    const {isComplete} = useGameCompletion();
    const [isGameTimeExpired, setGameTimeExpired] = useState(false);

    useEffect(() => {
        if (!crunchTimeStartTime) return;
        const delaySeconds = crunchTimeStartTime.add(20, 'seconds').unix() - dayjs().unix();
        const timeoutId = setTimeout(() => {
            // Ensure this component gets re-rendered at the relevant point in time.
            setGameTimeExpired(true);
        }, delaySeconds > 0 ? delaySeconds * 1000 : 0);
        return () => clearTimeout(timeoutId);
    }, [crunchTimeStartTime]);

    // Not using useGameOver here as that depends on ServerTime and our overlay isn't inside a <ServerTimeProvider/>
    const gameOver = !!crunchTimeStartTime && (isComplete || isGameTimeExpired);
    console.debug(`GamePlayOverlay:  crunchTimeStartTime: ${crunchTimeStartTime?.toISOString()}, isComplete:${isComplete}, now: ${dayjs().toISOString()}, gameOver: ${gameOver}`);

    return (
        <>
            <PlayersPanel avatar={SignlinesAvatarProvider}/>
            <Stack gap={0}>
                {localPlayer.isFacilitator && gameState.scene === 'LOBBY' &&
                    <FacilitatorActions/>
                }
                {localPlayer.isFacilitator && gameState.scene === 'PLAY' &&
                    <CrunchTimeButton/>
                }
                {localPlayer.isFacilitator && gameOver &&
                    <EndGameButton/>
                }
                <GameChatPanel avatar={SignlinesAvatarProvider}/>
            </Stack>
        </>
    );
}
