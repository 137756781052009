import {useFullscreen} from '@glark-newco/game-library';
import {Container, Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {PropsWithChildren} from 'react';
import {EXIT_FULLSCREEN, FULLSCREEN, OVERLAY} from '../assetManifest';

interface ButtonProps extends PropsWithChildren {
    position?: [number, number];
}

export function FullScreenButton({position}: ButtonProps) {
    const {isFullscreen, toggleFullscreen} = useFullscreen();
    return (
        <Container
            x={position?.['0'] ?? 0} y={position?.['1'] ?? 0}
            width={50} height={50}
        >
            <Sprite
                texture={Assets.get(OVERLAY)}
                width={50} height={50}
                x={0} y={0}
                click={toggleFullscreen}
                interactive={true}/>
            <Sprite
                texture={Assets.get(isFullscreen ? EXIT_FULLSCREEN : FULLSCREEN)}
                width={50} height={50}
                x={0} y={0}
                click={toggleFullscreen}
                interactive={true}/>
        </Container>
    );
}