import {ReviewStatsPanel} from './ReviewStatsPanel';

export function ReviewOverlay() {

    return (
        <>
            <ReviewStatsPanel/>
        </>
    );
}
