import {GamePayload, PlayerId} from '@glark-newco/game-library';
import {TileId} from './hooks/useSignlinesBoard';


export enum PayloadTypes { 'dealTilesEvent', 'endGameEvent', 'playTileEvent', 'removeTileEvent', 'playerAvatarChoiceEvent', 'startTimerEvent', 'crunchTimeEvent' }

export type PayloadType = typeof PayloadTypes[number];

interface Payload extends GamePayload {
    type: PayloadType;
}

export interface DealTilesEvent extends Payload {
    type: 'dealTilesEvent';
    playerId: PlayerId; // Tile recipient.  Not the player performing the action.
    tileIds: TileId[];
}

export interface PlayTileEvent extends Payload {
    type: 'playTileEvent';
    playerId: PlayerId; // used to display avatars. TODO - Remove & rely on GameEvent.playerId
    tileId: TileId;
    positionId: string;
}

export interface RemoveTileEvent extends Payload {
    type: 'removeTileEvent';
    tileId: TileId;
    positionId: string;
}

export interface PlayerAvatarChoiceEvent extends Payload {
    type: 'playerAvatarChoiceEvent';
    avatar: number[];
}

export interface StartTimerEvent extends Payload {
    type: 'startTimerEvent';
    duration: number; // minutes
}

export interface CrunchTimeEvent extends Payload {
    type: 'crunchTimeEvent';
}

export interface EndGameEvent extends Payload {
    type: 'endGameEvent';
}

export type TilePayload = DealTilesEvent | PlayTileEvent | RemoveTileEvent;

export type SignlinesPayload = TilePayload | PlayerAvatarChoiceEvent | StartTimerEvent | CrunchTimeEvent | EndGameEvent;

export function isDealTilesEvent(payload: GamePayload): payload is DealTilesEvent {
    return payload?.type == 'dealTilesEvent';
}

export function isPlayTileEvent(payload: GamePayload): payload is PlayTileEvent {
    return payload?.type == 'playTileEvent';
}

export function isRemoveTileEvent(payload: GamePayload): payload is RemoveTileEvent {
    return payload?.type == 'removeTileEvent';
}

export function isTilePayload(payload: GamePayload): payload is TilePayload {
    return isDealTilesEvent(payload) || isPlayTileEvent(payload) || isRemoveTileEvent(payload);
}

export function isPlayerAvatarPayload(payload: GamePayload): payload is PlayerAvatarChoiceEvent {
    return payload?.type == 'playerAvatarChoiceEvent';
}

export function isStartTimerPayload(payload: GamePayload): payload is StartTimerEvent {
    return payload?.type == 'startTimerEvent';
}

export function isCrunchTimePayload(payload: GamePayload): payload is CrunchTimeEvent {
    return payload?.type == 'crunchTimeEvent';
}

export function isEndGameEvent(payload: GamePayload): payload is EndGameEvent {
    return payload?.type == 'endGameEvent';
}
