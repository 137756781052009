import {Sound} from '@pixi/sound';
import {Assets} from 'pixi.js';
import {useCallback, useEffect, useRef} from 'react';
import {CRACKLING_FIRE} from '../assetManifest';

interface CracklingFireSoundEffectProps {
    scene: string
    loadingState: string
}

export function CracklingFireSoundEffect({scene, loadingState}: CracklingFireSoundEffectProps) {
    const cracklingFire = useRef<Sound | null>(null);

    const playCracklingFire = useCallback(() => {
        if (cracklingFire.current && !cracklingFire.current.isPlaying) {
            void cracklingFire.current.play({loop: true});
        }
    }, [cracklingFire.current]);

    useEffect(() => {
        if (loadingState !== 'PRELOAD' && cracklingFire.current) {
            if (['LOBBY', 'PLAY', 'REVIEW'].includes(scene)) {
                playCracklingFire();
            } else {
                cracklingFire.current.stop();
            }
        }
    }, [scene, cracklingFire.current, loadingState, playCracklingFire]);

    useEffect(() => {
        if (loadingState !== 'PRELOAD') {
            const sound = Assets.get<Sound>(CRACKLING_FIRE);
            if (sound) {
                cracklingFire.current = sound;
            }
        }
    }, [loadingState]);
    
    return <></>;
}