import {useServerTime} from '@glark-newco/game-library';
import {Sound} from '@pixi/sound';
import {Assets} from 'pixi.js';
import {useEffect, useRef} from 'react';
import {TENSION} from '../assetManifest';
import {useCrunchTime} from '../hooks/useCrunchTime';
import {useGameOver} from '../hooks/useGameOver';
import {useGameEnd} from '../state/signlinesGameState';

export function TensionSoundEffect() {
    const tension = useRef(Assets.get<Sound>(TENSION));
    const {isCrunchTime} = useCrunchTime();
    const planningEndTime = useGameEnd(); // This hook is badly named
    const {currentTime} = useServerTime();
    const isNearlyCrunctime = planningEndTime.subtract(30, 'seconds').isBefore(currentTime);
    const {isGameOver} = useGameOver();

    // Handles crunchtime being started early, or starting the sound effect before 'planning time' runs out.
    const tensionNeeded = (isCrunchTime || isNearlyCrunctime) && !isGameOver;

    useEffect((): () => void => {
        if (tensionNeeded) {
            void tension.current.play({loop: true});
        } else {
            tension.current.stop();
        }

        // Stop on un-mount
        return () => tension.current.stop();
    }, [tensionNeeded]);

    return <></>;
}