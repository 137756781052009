import {AvatarProps, Sprite} from '@glark-newco/game-library';
import {Box} from '@mantine/core';
import {useMemo} from 'react';
import {categories, usePlayerAvatars} from '../hooks/usePlayerAvatars';

export const generateAvatarOptions = (avatar: number[] | undefined) => {
    if (!avatar) { 
        return [];
    }
    const options: string[] = [];
    avatar.map((option: number, i: number) => {
        if (option) {options.push(`avatar_${categories[i].name}_${String(option).padStart(2, '0')}`); }
    });
    return options;
};

export function AvatarSprite({avatar}: {avatar: number[] | undefined}) {

    const avatarOptions = useMemo(() => {
        return generateAvatarOptions(avatar);
    }, [avatar]);
    
    return (
        <Box style={{
            position: 'relative',
            display: 'block',
        }}>
            {avatarOptions.map(avatarOption => {
                return (<Box
                    key={avatarOption}
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                    }}>
                    <Sprite sprite={avatarOption}/>
                </Box>);
            })}
        </Box>
    );
}

function SignlinesAvatar({playerId}: AvatarProps) {
    const avatar: number[] = usePlayerAvatars().avatars[playerId];
    return <AvatarSprite avatar={avatar}/>;
}

// A component-provider, not a true react-component, so it can't contain hooks.
export function SignlinesAvatarProvider({playerId}: AvatarProps) {
    return <SignlinesAvatar playerId={playerId} />;
}
