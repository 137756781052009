import type {IPointData} from '@pixi/core';

export const cols = 'ABCDE'.split('');
export const rows = '012345'.split('');

export const TILE_WIDTH = 180;
export const TILE_HEIGHT = 99;
export const BOARD_LEFT = 477;
export const BOARD_TOP = 88;
export const X_PADDING = 17;
export const Y_PADDING = 12;

export function columnLeftPixels(col: string) {
    return BOARD_LEFT + (cols.indexOf(col) * (TILE_WIDTH + X_PADDING));
}

export function rowTopPixels(row: string) {
    return BOARD_TOP + (rows.indexOf(row) * (TILE_HEIGHT + Y_PADDING));
}

const columnPixels = cols.map((col) => {
    const start = columnLeftPixels(col);
    return [start, start + TILE_WIDTH];
});
const rowPixels = rows.map((row) => {
    const start = rowTopPixels(row);
    return [start, start + TILE_HEIGHT];
});

export function colIndexFromPoint(global: IPointData): number {
    return columnPixels.findIndex((candidate) => global.x >= candidate[0] && global.x <= candidate[1]);
}

export function rowIndexFromPoint(global: IPointData): number {
    return rowPixels.findIndex((candidate) => global.y >= candidate[0] && global.y <= candidate[1]);
}

export function getAdjacentTiles(tile: string): string[] {
    const [handTileRow, handTileCol] = (tile.split('') as [string, string]);
    const hr: number = handTileRow.charCodeAt(0);
    const hc: number = handTileCol.charCodeAt(0);
    const char = (n: number) => String.fromCharCode(n);
    return [
        char(hr + 1) + char(hc),
        char(hr - 1) + char(hc),
        char(hr) + char(hc + 1),
        char(hr) + char(hc - 1),
    ];
}

export function getColNumber(cell: string): number {
    if (cell.length !== 2)
        throw new Error(`Invalid cell ${cell}`);
    const index = cols.indexOf(cell[0]);
    if (index < 0)
        throw new Error(`Unknown column for cell ${cell}`);
    return index;
}

export function getRowNumber(cell: string): number {
    if (cell.length !== 2)
        throw new Error(`Invalid cell ${cell}`);
    const index = rows.indexOf(cell[1]);
    if (index < 0)
        throw new Error(`Unknown row for cell ${cell}`);
    return index;
}
