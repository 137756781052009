import {useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import {useCallback, useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {PlayerAvatarChoiceEvent, SignlinesPayload} from '../SignlinesPayloadTypes';
import {playerAvatarStateSelector} from '../state/signlinesGameState';

// Stops multiple avatars being generated due to usePlayerAvatars hook being used in multiple places in the UI
let initialAvatarGenerated: boolean = false;

interface Category {
    name: string, 
    count: number,
    generic: number,
    noneAllowed?: boolean,
}

export const categories: Category[] = [
    {
        name: 'body', 
        count: 6,
        generic: 1,
    },
    {
        name: 'hair', 
        count: 22,
        generic: 2,
        noneAllowed: true,
    },
    {
        name: 'clothing', 
        count: 8,
        generic: 1,
    },
    {
        name: 'beard', 
        count: 7,
        generic: 1,
        noneAllowed: true,
    },
    {
        name: 'accessory', 
        count: 10,
        generic: 1,
        noneAllowed: true,
    },
    {
        name: 'glasses', 
        count: 11,
        generic: 1,
        noneAllowed: true,
    },
    {
        name: 'hat', 
        count: 8,
        generic: 1,
        noneAllowed: true,
    },
];

export const generateRandomAvatar = () => {
    return Array.from({length: 7}, (_, i) => {
        const max = categories[i].count;
        const min = categories[i].noneAllowed ? 0 : 1;
        return Math.floor(Math.random() * (max - min + 1) + min);
    });
};

export function usePlayerAvatars() {
    const {connectionReady} = useGameServerConnection();
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const {localPlayer} = usePlayers();
    const avatars = useRecoilValue(playerAvatarStateSelector);

    const generateRandomAvatarEvent = useCallback(() => {
        publishPlayerAction({
            type: 'playerAvatarChoiceEvent',
            avatar: generateRandomAvatar(),
        } as PlayerAvatarChoiceEvent);
    }, [publishPlayerAction]);


    useEffect(() => {
        if (connectionReady && localPlayer && localPlayer.playerId) {
            const localAvatar = avatars[localPlayer.playerId];
            if (!localAvatar && !initialAvatarGenerated) {
                generateRandomAvatarEvent();
                initialAvatarGenerated = true;
            }
        }
    }, [localPlayer, localPlayer.playerId, connectionReady, avatars]);
    
    return {avatars};
}