import {PixiRef, Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {useEffect, useRef} from 'react';

export type ISprite = PixiRef<typeof Sprite>;

interface FlashSymbolAnimationProps {
    x: number,
    y: number,
    texture: string,
}

export function FlashSymbolAnimation(props: FlashSymbolAnimationProps) {
    const spriteRef = useRef<ISprite>(null);
    const startTimeRef = useRef<number>(0);
    const animationTimeMs = 750;

    useEffect(() => {
        // Accessing everything with "window" so i can properly stub them in cypress tests
        startTimeRef.current = window.performance.now();

        const animate = (time: number) => {
            const elapsed = time - startTimeRef.current;
            const progress = Math.min(elapsed / animationTimeMs, 1);

            const scale = 0.5 + progress * 1.0;
            const alpha = 1 - progress;

            if (spriteRef.current) {
                spriteRef.current.scale = {x: scale, y: scale};
                spriteRef.current.alpha = alpha;
            }
        
            if (progress < 1) {
                window.requestAnimationFrame(animate);
            } else {
                if (spriteRef.current) {
                    spriteRef.current.visible = false;
                }
            }
        };

        window.requestAnimationFrame(animate);
    }, []);

    return <Sprite 
        ref={spriteRef} texture={Assets.get(props.texture)}
        x={props.x} y={props.y} anchor={.5} zIndex={2}/>;
}
