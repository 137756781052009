import {Button, Group} from '@mantine/core';
import {IconPlayerPlay} from '@tabler/icons-react';
import {useCrunchTime} from '../hooks/useCrunchTime';

export function CrunchTimeButton() {
    const {publishCrunchTimeEvent, crunchTimeStartTime} = useCrunchTime();

    if (crunchTimeStartTime)
        return <></>;

    return (
        <Group 
            style={{
                width: '20%',
                position: 'absolute',
                pointerEvents: 'initial',
                right: '1rem',
                top: '2.5rem',
            }}>
            <Button 
                data-testid="crunchtime-override"
                role='button'
                name='start game'
                rightSection={<IconPlayerPlay size={14}/>}
                radius={'xl'} 
                variant="gradient" 
                onClick={() => publishCrunchTimeEvent()}
                gradient={{from: 'blue', to: 'teal', deg: 90}}>
                Start CrunchTime
            </Button>
        </Group>
    );
}