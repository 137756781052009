import {atom} from 'recoil';

export interface AvatarEdit {
    inEdit: boolean;
}

const defaultAvatarEditState: AvatarEdit = {
    inEdit: false,
};

export const AvatarEditStateAtom = atom<AvatarEdit>({
    key: 'avatarEditAtom',
    default: defaultAvatarEditState,
});
