import {GameAction, isPlayerAction, PlayerAction, PlayerId,
    ReplacedPlayerMap,
    useGameState,
    usePlayers,
    useReplacedPlayerMap,
} from '@glark-newco/game-library';
import {useMemo} from 'react';
import {
    isCrunchTimePayload,
    isDealTilesEvent,
    isPlayTileEvent,
    isRemoveTileEvent,
    isTilePayload,
    PlayTileEvent,
    RemoveTileEvent,
} from '../SignlinesPayloadTypes';
import {useCrunchTimeStartTime, useTileEvents} from '../state/signlinesGameState';
import {cols, getColNumber, getRowNumber, rows} from '../utils/GameBoardUtils';


export type TileId = string;

export interface Tile {
    tileId: TileId;
}

export interface BoardCell {
    location: string;
    tileId?: TileId;
    playerId?: string;
}

function tileFromId(tileId: TileId): Tile {
    return {
        tileId,
    };
}

function emptyBoard(): BoardCell[] {
    return new Array(cols.length * rows.length).fill(1).map((_, cellIndex) => {
        const colIndex = cellIndex % cols.length;
        const location = cols[colIndex] + String(Math.floor(cellIndex / cols.length));
        return {
            location,
        } as BoardCell;
    });
}

function applyPlayEvent(gameBoard: BoardCell[], event: PlayTileEvent | RemoveTileEvent) {
    const column = getColNumber(event.positionId);
    const row = getRowNumber(event.positionId);
    const cellIndex = row * cols.length + column;
    if (isPlayTileEvent(event) && gameBoard[cellIndex].tileId === undefined) {
        gameBoard[cellIndex] = {
            ...gameBoard[cellIndex],
            playerId: event.playerId,
            tileId: event.tileId,
        };
    } else if (isRemoveTileEvent(event) && gameBoard[cellIndex].tileId === event.tileId) {
        gameBoard[cellIndex] = {
            ...gameBoard[cellIndex],
            playerId: undefined,
            tileId: undefined,
        };
    }
    return gameBoard;
}

export function useGameBoard(): BoardCell[] {
    const tileEvents = useTileEvents();

    return useMemo(() => {
        return tileEvents
            .filter(e => isPlayTileEvent(e) || isRemoveTileEvent(e))
            .map(e => e as unknown as PlayTileEvent | RemoveTileEvent)
            .reduce(applyPlayEvent, emptyBoard());
    }, [tileEvents]);
}

export function useLocalPlayerTiles(): Tile[] {
    const {localPlayer} = usePlayers();
    const tileEvents = useTileEvents();
    const playedTiles = useGameBoard()
        .filter(c => !!c.tileId)
        .map(c => c.tileId!);

    return useMemo(() => {
        return tileEvents
            .filter(e => isDealTilesEvent(e))
            .map(e => e)
            .filter(e => e.playerId === localPlayer?.playerId)
            .flatMap(e => e.tileIds)
            .filter(tileId => !playedTiles.includes(tileId))
            .map(tileFromId);

    }, [localPlayer, tileEvents, playedTiles.join(',')]);
}

/////////////////////////  Completion stats  ///////////////////////////

interface SignlinesCompletionStats {
    totalTiles: number;
    correctTiles: number;
    incorrectTiles: number;
    isComplete: boolean;
}

export function useGameCompletion(): SignlinesCompletionStats {
    const gameBoard = useGameBoard();

    return useMemo(() => {
        const emptyStats = {
            totalTiles: 0,
            correctTiles: 0,
            incorrectTiles: 0,
            isComplete: false,
        } as SignlinesCompletionStats;

        return gameBoard.reduce((partial: SignlinesCompletionStats, cell: BoardCell) => {
            const correctTile = !!cell.tileId && cell.tileId === cell.location;
            const wrongTile = !!cell.tileId && cell.tileId !== cell.location;
            return {
                totalTiles: partial.totalTiles + 1,
                correctTiles: correctTile ? partial.correctTiles + 1 : partial.correctTiles,
                incorrectTiles: wrongTile ? partial.incorrectTiles + 1 : partial.incorrectTiles,
                isComplete: correctTile && partial.correctTiles === partial.totalTiles,
            } as SignlinesCompletionStats;
        }, emptyStats);
    }, [gameBoard]);
}


/////////////////////////  Player stats  ///////////////////////////

interface PlayerState {
    dealtTiles: TileId[],
    correctTilesCount: number,
    incorrectTilesCount: number,
    removedTilesCount: number,
    crunchTimePlays: number,
}

export interface PlayerStats {
    handEmptySequence: number;
    allAccurateSequence: number;
    removedTilesCount: number;
    crunchTimeCount: number;
}

interface PlayerStatsMap {
    [key: PlayerId]: PlayerStats,
}
interface PlayerStateMap {
    [key: TileId]: PlayerState,
}

function hasTilePayload(event: GameAction): event is PlayerAction  {
    return isPlayerAction(event) && (isTilePayload(event.payload));
}

function maybeReplacePlayerId(playerId: PlayerId, replacedPlayers: ReplacedPlayerMap) {
    if (playerId in replacedPlayers)
        return replacedPlayers[playerId];
    return playerId;
}

function findPlayerDealt(tileId: TileId, playerState: PlayerStateMap): PlayerId {
    const entry = Object.entries(playerState).find(([, state]) => state.dealtTiles.includes(tileId));
    if (!entry)
        throw new Error(`Error tile [${tileId}] hasn't been dealt.`);
    return  entry[0];
}

const emptyState = {
    dealtTiles: [],
    correctTilesCount: 0,
    incorrectTilesCount: 0,
    removedTilesCount: 0,
    crunchTimePlays: 0,
} as PlayerState;

function updateState(playerState: PlayerStateMap, action: PlayerAction, replacedPlayers: ReplacedPlayerMap, crunched: boolean): PlayerStateMap {
    if (isDealTilesEvent(action.payload)) {
        const playerId = maybeReplacePlayerId(action.payload.playerId, replacedPlayers);
        const existing = playerState[playerId] ?? emptyState;
        return {
            ...playerState,
            [playerId]: {
                ...existing,
                dealtTiles: [...existing.dealtTiles, ...action.payload.tileIds],
            },
        };
    }
    if (isPlayTileEvent(action.payload)) {
        const playerId = maybeReplacePlayerId(action.payload.playerId, replacedPlayers);
        const correct = action.payload.tileId === action.payload.positionId;
        const existing = playerState[playerId] ?? emptyState;
        return {
            ...playerState,
            [playerId]: {
                ...existing,
                correctTilesCount: existing.correctTilesCount + (correct ? 1 : 0),
                incorrectTilesCount: existing.incorrectTilesCount + (!correct ? 1 : 0),
                crunchTimePlays: existing.crunchTimePlays + (crunched ? 1 : 0),
            },
        };
    }
    if (isRemoveTileEvent(action.payload)) {
        if (!action.payload.tileId) return playerState;  // Shouldn't be possible. Double click?
        const playerId = maybeReplacePlayerId(findPlayerDealt(action.payload.tileId, playerState), replacedPlayers);
        const correct = action.payload.tileId === action.payload.positionId;
        const existing = playerState[playerId] ?? emptyState;
        return {
            ...playerState,
            [playerId]: {
                ...existing,
                correctTilesCount: existing.correctTilesCount - (correct ? 1 : 0),
                incorrectTilesCount: existing.incorrectTilesCount - (!correct ? 1 : 0),
                removedTilesCount: existing.removedTilesCount + 1,
            },
        };
    }
    throw new Error(`Unrecognised event ${JSON.stringify(action)}`);
}


const emptyStats = {
    handEmptySequence: 0,
    allAccurateSequence: 0,
    removedTilesCount: 0,
    crunchTimeCount: 0,
};
function updateStats(previousStats: PlayerStatsMap, playerState: PlayerStateMap, sequence: number): PlayerStatsMap {
    function isHandEmpty(state: PlayerState):boolean {
        return state.dealtTiles.length <= (state.correctTilesCount + state.incorrectTilesCount);

    }
    function isAllAccurate(state: PlayerState) {
        return state.dealtTiles.length <= state.correctTilesCount;
    }
    const entries = Object.entries(playerState).map(([playerId, state]):[PlayerId, PlayerStats]=> {
        const previous  = previousStats[playerId] ?? emptyStats;
        const stats = {
            handEmptySequence: (isHandEmpty(state) && !previous.handEmptySequence) ? sequence : previous.handEmptySequence,
            allAccurateSequence: (isAllAccurate(state) && !previous.allAccurateSequence) ?  sequence : previous.allAccurateSequence,
            removedTilesCount: state.removedTilesCount,
            crunchTimeCount: state.crunchTimePlays,
        } as PlayerStats;
        return [playerId, stats];
    });

    return entries.reduce((partial, [playerId, stats])=> {
        partial[playerId] = stats;
        return partial;
    }, {} as PlayerStatsMap);
}

export function usePlayerStats(): PlayerStatsMap {
    const crunchTimeStartTime = useCrunchTimeStartTime();
    const replacedPlayers = useReplacedPlayerMap();
    const {gameState: {gameActions}} = useGameState();

    return useMemo(() => {
        const emptyMap = {crunched: false, stats: {} as PlayerStatsMap, playerState: {} as PlayerStateMap};

        const {stats} = gameActions.reduce((partial: {crunched: boolean, stats: PlayerStatsMap, playerState: PlayerStateMap}, event: GameAction) => {

            if (isPlayerAction(event) && isCrunchTimePayload(event.payload))
                return {...partial, crunched: true};

            if (!hasTilePayload(event))
                return partial;

            const playerState =  updateState(partial.playerState, event, replacedPlayers, partial.crunched);
            const playerStats = updateStats(partial.stats, playerState, event.sequence ?? 0);
            return {
                ...partial, playerState, stats: playerStats,
            };
        }, emptyMap);
        return stats;
    }, [gameActions, crunchTimeStartTime]);
}

/////////////////////////  Planning accuracy  ///////////////////////////
// Number of tiles placed correctly at the end of Planning phase / start of Crunch-Time.

export function usePlaningAccuracy(): number {
    const crunchTimeStartTime = useCrunchTimeStartTime();
    const {gameState: {gameActions}} = useGameState();

    return useMemo(() => {
        const emptyMap = {planingCorrect:0, crunched:false};

        const {planingCorrect} = gameActions.reduce((partial: {planingCorrect:number, crunched:boolean}, event: GameAction) => {

            if (isPlayerAction(event) && !partial.crunched) {
                if (isCrunchTimePayload(event.payload))
                    return {...partial, crunched: true};

                if (isPlayTileEvent(event.payload)) {
                    const correct = event.payload.tileId === event.payload.positionId;
                    return {
                        ...partial,
                        planingCorrect: partial.planingCorrect + (correct ? 1 : 0),
                    };
                }
                if (isRemoveTileEvent(event.payload)) {
                    const correct = event.payload.tileId === event.payload.positionId;
                    return {
                        ...partial,
                        planingCorrect: partial.planingCorrect - (correct ? 1 : 0),
                    };
                }
            }
            return partial;
        }, emptyMap);

        return planingCorrect;

    }, [gameActions, crunchTimeStartTime]);
}
