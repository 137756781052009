import {GameEvent, isPlayerAction, Scene, SceneMutator} from '@glark-newco/game-library';
import {isDealTilesEvent, isEndGameEvent} from '../SignlinesPayloadTypes';


export const signlinesSceneMutator: SceneMutator = (existing: Scene, event: GameEvent)=> {
    if (isPlayerAction(event) && isDealTilesEvent(event.payload)) {
        return 'PLAY';
    } else if (isPlayerAction(event) && isEndGameEvent(event.payload)) {
        return 'REVIEW';
    }
    return existing;
};
