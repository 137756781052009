import {TextStyle} from 'pixi.js';

export const textStyle = (style?: Partial<TextStyle>) => {
    return new TextStyle({
        align: 'center',
        fontWeight: 'bold',
        fill: ['#26f7a3', '#01d27e'],
        stroke: '#eef1f5',
        strokeThickness: 1,
        letterSpacing: 2,
        wordWrap: false,
        wordWrapWidth: 350,
        ...style,
    });
};