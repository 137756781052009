import {Sprite} from '@glark-newco/game-library';
import {Carousel} from '@mantine/carousel';
import {Box, Text} from '@mantine/core';
import {IconBrandRedhat, IconCancel, IconDiamondFilled, IconMan, IconMoodBoy, IconMoustache, IconShirt, IconSunglasses} from '@tabler/icons-react';
import {useMemo, useState} from 'react';
import styles from './CustomiseCharacter.module.css';
import {categories} from '../hooks/usePlayerAvatars';

interface AvatarOption {
    id: number;
    image: string;
}

interface AvatarOptionSpriteProps {
    image: string,
    style?: object,
    className?: string,
    selectAvatarOption: () => void,
}

interface AvatarOptionSelectorProps {
    category: number;
    selected: number;
    handleAvatarChange: (c: number, i: number) => void,
}

interface CategorySelectorProps {
    categoryIndex: number;
    setCategory: (foo: number) => void, 
}

function generateOptions(category: string, count: number): AvatarOption[] {
    const options = [];
    for (let i: number = 1; i < (count + 1); i++) {
        options.push({
            id: i,
            image: `avatar_trimmed_${category}_${String(i).padStart(2, '0')}`,
        });
    }
    return options;
}

function AvatarOptionSprite({image, selectAvatarOption, className, style} : AvatarOptionSpriteProps) {
    return (
        <Box style={style}>
            <Sprite
                sprite={image}
                className={className}
                onClick={() => selectAvatarOption()}/> 
        </Box>
    );
}

function AvatarOptionSelector({category, selected, handleAvatarChange}: AvatarOptionSelectorProps) {
    const categoryName = categories[category].name;
    const categoryOptionCount = categories[category].count;

    const avatarOptions = useMemo(
        () => generateOptions(categoryName, categoryOptionCount),
        [category, categoryOptionCount],
    );

    const slides = avatarOptions.map((avatarOption: AvatarOption) => {
        return (
            <Carousel.Slide key={avatarOption.id} style={{position: 'relative'}}>
                <AvatarOptionSprite 
                    image={avatarOption.image}
                    className={selected === avatarOption.id ? styles.selectedOption : ''}
                    selectAvatarOption={() => handleAvatarChange(category, avatarOption.id)}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '100%',
                    }}
                />
            </Carousel.Slide>
        );
    });

    return (
        <Carousel
            controlsOffset="xs"
            slideSize="25%"
            slidesToScroll={3}
            slideGap="1%"
            align="start"
            height="100%"
            draggable={true}
            style={{padding: '0 10%', height: '100%'}}
            data-testid={'test-carousel'}
            nextControlIcon={<img src='images/scroll right.png' style={{width: '1.6rem', height: '1.6rem'}} alt={'scroll right'}/>}
            previousControlIcon={<img src='images/scroll left.png' style={{width: '1.6rem', height: '1.6rem'}} alt={'scroll left'}/>}>
            {categories[category].noneAllowed ? (
                <Carousel.Slide key={'option-0'} style={{position: 'relative'}}>
                    <Box 
                        className={selected === 0 ? styles.selectedOption : ''}
                        onClick={() => handleAvatarChange(category, 0)}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}>
                        <IconCancel style={{width:'100%', height: '100%', padding: '20%'}}/>
                    </Box>
                </Carousel.Slide>
            ) : <></>}
            {slides}
        </Carousel>
    );
}

function CategorySelector({categoryIndex, setCategory}: CategorySelectorProps) {
    const icons = [
        <IconMan style={{width: '2.5rem', height: '2.5rem'}} key={'IconMan'}/>,
        <IconMoodBoy style={{width: '2.5rem', height: '2.5rem'}} key={'IconMoodBoy'}/>,
        <IconShirt style={{width: '2.5rem', height: '2.5rem'}} key={'IconShirt'}/>,
        <IconMoustache style={{width: '2.5rem', height: '2.5rem'}} key={'IconMoustache'}/>,
        <IconDiamondFilled style={{width: '2.5rem', height: '2.5rem'}} key={'IconDiamondFilled'}/>,
        <IconSunglasses style={{width: '2.5rem', height: '2.5rem'}} key={'IconSunglasses'}/>,
        <IconBrandRedhat style={{width: '2.5rem', height: '2.5rem'}} key={'IconBrandRedhat'}/>,
    ];

    const options = categories.map((catagory, i) => (
        <Box
            key={catagory.name}
            data-testid={`${catagory.name}_selector`}
            style={{width: `${100 / categories.length}%`}}
            className={categoryIndex === i ? styles.selectedCategory : ''}
            onClick={() => setCategory(i)}>
            {icons[i]}
        </Box>
    ));

    return (
        <Box style={{textAlign: 'center'}} data-testid={'test-category-selector'}>
            <Box style={{display: 'flex', justifyContent: 'center', paddingTop: '2%'}}>
                {options.slice(0, 4)}
            </Box>
            <Box style={{display: 'flex', justifyContent: 'center', paddingTop: '2%'}}>
                {options.slice(4, 7)}
            </Box>
        </Box>
    );
}

interface CustomiseCharacterProps {
    tempAvatar: number[],
    setTempAvatar: React.Dispatch<React.SetStateAction<number[]>>,
}

export function CustomiseCharacter({tempAvatar, setTempAvatar}: CustomiseCharacterProps) {
    const [category, setCategory] = useState<number>(0);

    const handleAvatarChange = (c: number, i: number) => {
        setTempAvatar(prevState => {
            const updatedAvatar = [...prevState];
            updatedAvatar[c] = i;
            return updatedAvatar;
        });
    };

    return (
        <Box
            style={{
                width: '26%',
                height: '54%',
                backgroundColor: 'rgba(0,0,0,0)',
                pointerEvents: 'initial',
                position: 'absolute',
                right: '50.3%',
                top: '26%',
                display: 'block',
                padding: '3.2% 1.2%',
            }}>
            <Box style={{
                textAlign: 'center',
                marginTop: '2%',
                paddingTop: '1%',
                height: '33%',
            }}>
                <Text style={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                }}>Custom Character</Text>
                <CategorySelector categoryIndex={category} setCategory={setCategory}/>
            </Box>
            <Box style={{
                textAlign: 'center',
                borderTop: '1px dashed #b4a17a',
                marginTop: '2%',
                paddingTop: '1%',
                height: '10%',
                position: 'relative',
            }}>
                <Text style={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                }}>Select {categories[category].name}</Text>
            </Box>
            <Box 
                style={{height: '57%'}}>
                <AvatarOptionSelector category={category} selected={tempAvatar[category]} handleAvatarChange={handleAvatarChange}/>
            </Box>
        </Box>
    );
}