import {PixiRef, Sprite} from '@pixi/react';
import {RefObject, useEffect, useRef} from 'react';
import {getRowNumber} from '../utils/GameBoardUtils';


export type ISprite = PixiRef<typeof Sprite>;

interface TileRemoveAnimationProps {
    spriteRef: RefObject<ISprite> | null,
    x: number,
    y: number,
    cellLocation: string | undefined,
    isDeleted: boolean,
    onRemoveAnimationEnd: (() => void) | undefined
    setIsAnimating: (value: boolean) => void
}

export function useTileRemoveAnimation({spriteRef, x, y, cellLocation, isDeleted, onRemoveAnimationEnd, setIsAnimating}: TileRemoveAnimationProps) {
    const startTimeRef = useRef<number>(0);
    const startPositionRef = useRef({x, y});
    const endPosition = {x: 950, y: 800};
    const rowNumber = cellLocation ? getRowNumber(cellLocation) : 1;
    const animationTimeMs = 450 - ((rowNumber + 1) * 50);

    useEffect(() => {
        if (!isDeleted) {
            if (spriteRef && spriteRef.current) {
                // Resets to normal if not deleted
                spriteRef.current.x = startPositionRef.current.x;
                spriteRef.current.y = startPositionRef.current.y;
                spriteRef.current.visible = true;
                spriteRef.current.zIndex = 0;
            }
            return;
        }

        startTimeRef.current = performance.now();

        if (spriteRef && spriteRef.current) {
            spriteRef.current.zIndex = 1;
        }

        const diff = {x: endPosition.x - startPositionRef.current.x, y: endPosition.y - startPositionRef.current.y};
        
        const animate = (time: number) => {
            setIsAnimating(true);
            const elapsed = time - startTimeRef.current;
            const progress = Math.min(elapsed / animationTimeMs, 1);

            if (spriteRef && spriteRef.current) {
                spriteRef.current.x = startPositionRef.current.x + (diff.x * progress);
                spriteRef.current.y = startPositionRef.current.y + (diff.y * progress);
            }
        
            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                if (spriteRef && spriteRef.current) {
                    spriteRef.current.visible = false;
                }
                if (onRemoveAnimationEnd) {
                    onRemoveAnimationEnd();
                }
                setIsAnimating(false);
            }
        };

        requestAnimationFrame(animate);
    }, [isDeleted]);

    return null;
}