import dayjs, {Dayjs} from 'dayjs';
import {cols, getColNumber, getRowNumber} from './GameBoardUtils';
import {BoardCell} from '../hooks/useSignlinesBoard';

// Converts to radians for PixiJs rotation values
export const radians = (degrees: number) => {
    return degrees * (Math.PI / 180);
};

// Plays affect on Pixi Sprite
export const play = (
    start: number,
    end: number,
    current: number,
    effect: (value: number) => void,
    from: number,
    to: number,
) => {
    if (end < start) {
        throw new Error('Animation end is before start');
    }
    if (current < start) { return; }
    if (current > end) { return; }

    const progress = Math.max(Math.min((
        (current - start) / (end - start)
    ), 1), 0);
    const value = from + ((to - from) * progress);
  
    effect(value);
};

export const crunchTimeAnimationDelay = (crunchTimeStartTime: Dayjs | undefined, cell: BoardCell) => {
    if (!crunchTimeStartTime) { return 0; }
    const delay = (getRowNumber(cell.location) * cols.length + getColNumber(cell.location)) * 350; // ms
    const now = dayjs();
    const pause = (crunchTimeStartTime.unix() + delay) - now.unix();
    return !pause || pause < 0 ? 0 : pause;
};
