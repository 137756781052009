import {FederatedPointerEvent} from '@pixi/events';
import {Container} from '@pixi/react';
import {ReactNode} from 'react';


interface SceneProps {
    children: ReactNode;
    mouseMoveHandler?: (event: FederatedPointerEvent) =>void;
}

export function Scene({children, mouseMoveHandler}: SceneProps) {

    return (
        <Container width={1920} height={1080} onmousemove={mouseMoveHandler} interactive>
            {children}
        </Container>
    );
}