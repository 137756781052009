import {useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import _ from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {CrunchTimeEvent, SignlinesPayload} from '../SignlinesPayloadTypes';
import {useCrunchTimeStartTime} from '../state/signlinesGameState';

export function useCrunchTime() {
    const {localPlayer} = usePlayers();
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const crunchTimeStartTime = useCrunchTimeStartTime();

    const isCrunchTime = useMemo(() => {
        return !!crunchTimeStartTime;
    }, [crunchTimeStartTime]);

    const publishCrunchTimeEvent = useCallback(() => {
        if (!localPlayer.isFacilitator) return;
        publishPlayerAction({
            type: 'crunchTimeEvent',
        } as CrunchTimeEvent);
    }, [localPlayer.isFacilitator, publishPlayerAction]);

    return {isCrunchTime, publishCrunchTimeEvent, crunchTimeStartTime};
}

export function useCrunchTimeTransition(delay: number = 0): boolean {
    const crunchTimeStartTime = useCrunchTimeStartTime();
    const initialCrunchTimeState = useRef(crunchTimeStartTime);
    const [playAnimation, setPlayAnimation] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(()=> {
            setPlayAnimation(!_.isEqual(crunchTimeStartTime, initialCrunchTimeState.current));
        }, delay);
    }, [crunchTimeStartTime]);

    return playAnimation;
}