import {Player, PlayerBadge, PlayerId, usePlayers} from '@glark-newco/game-library';
import {Center, Grid, Paper, Text, Title} from '@mantine/core';
import {ReactNode} from 'react';
import {SignlinesAvatarProvider} from './SignlinesAvatar';
import {PlayerStats, useGameCompletion, usePlaningAccuracy, usePlayerStats} from '../hooks/useSignlinesBoard';


function title(titleText: string, subText?: string) {
    return <>
        <Title order={3}>{titleText}</Title>
        <Text>{subText}</Text>
    </>;
}

function firstPlayer(players: Player[], playerIds: PlayerId[]): ReactNode {
    const found = playerIds.length > 0 ? players.find(p => p.playerId === playerIds[0]) : undefined;
    return <>
        {!!found &&
            <PlayerBadge player={found} avatar={SignlinesAvatarProvider}/>
        }
    </>;
}

export function ReviewStatsPanel() {
    const completion = useGameCompletion();
    const planingCorrect = usePlaningAccuracy();
    const playerStats = usePlayerStats();
    const {players} = usePlayers();

    const correctTilesPercent = completion.correctTiles / completion.totalTiles * 100;
    const planingTilesPercent = planingCorrect / completion.totalTiles * 100;

    const fastestPlayer = firstPlayer(players, Object.entries(playerStats)
        .filter(([,stats]) => stats.handEmptySequence > 0)
        .sort(([,a], [,b]) => a.handEmptySequence - b.handEmptySequence)
        .map(([playerId]:[string, PlayerStats]) => playerId));

    const accuratePlayer = firstPlayer(players, Object.entries(playerStats)
        .filter(([,stats]) => stats.allAccurateSequence > 0)
        .sort(([,a], [,b]) => a.allAccurateSequence - b.allAccurateSequence)
        .map(([playerId]:[string, PlayerStats]) => playerId));

    const decisivePlayer = firstPlayer(players, Object.entries(playerStats)
        // Never null. Could match multiple players, default is 0 for all players.
        .sort(([,a], [,b]) => a.removedTilesCount - b.removedTilesCount)
        .map(([playerId]:[string, PlayerStats]) => playerId));

    const crunchPlayer = firstPlayer(players, Object.entries(playerStats)
        .filter(([,stats]) => stats.crunchTimeCount > 0)
        // Descending.  Most tiles placed during "Crunch time"
        .sort(([,a], [,b]) => b.crunchTimeCount - a.crunchTimeCount)
        .map(([playerId]:[string, PlayerStats]) => playerId));

    return <>
        <Center style={{height: '100%'}} p={'xl'}>
            <Paper withBorder p={'xl'} radius={'xl'} style={{
                width: '45%',
                height: '55%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: '#ccc',
            }}>
                <Center mb={'xl'}><Title order={2}>Game Over</Title></Center>
                <Grid columns={2} mb={'xl'}>
                    <Grid.Col span={1}>{title('Correct tiles')}</Grid.Col>
                    <Grid.Col span={1}><Center>
                        <Text size="xl" fw={700} data-testid={'correct_tiles'}>{completion.correctTiles.toString()}/{completion.totalTiles.toString()}</Text>
                        <Text size="xl" pl={'md'} data-testid={'correct_percent'}>({correctTilesPercent.toLocaleString('en-UK', {maximumSignificantDigits: 2})}%)</Text>
                    </Center></Grid.Col>

                    <Grid.Col span={1}>{title('Planning accuracy')}</Grid.Col>
                    <Grid.Col span={1}><Center>
                        <Text size="xl" fw={700} data-testid={'planning_tiles'}>{planingCorrect.toString()}/{completion.totalTiles.toString()}</Text>
                        <Text size="xl" pl={'md'} data-testid={'planning_percent'}>({planingTilesPercent.toLocaleString('en-UK', {maximumSignificantDigits: 2})}%)</Text>
                    </Center></Grid.Col>
                </Grid>

                <Grid columns={2}>
                    <Grid.Col span={1}>{title('Fastest Finger First', 'first to place all tiles')}</Grid.Col>
                    <Grid.Col span={1} data-testid={'fastest_player'}>{fastestPlayer}</Grid.Col>

                    <Grid.Col span={1}>{title('Master of accuracy', 'first to place all tiles correctly')}</Grid.Col>
                    <Grid.Col span={1} data-testid={'accurate_player'}>{accuratePlayer}</Grid.Col>

                    <Grid.Col span={1}>{title('Most decisive', 'least number of removed tiles')}</Grid.Col>
                    <Grid.Col span={1} data-testid={'decisive_player'}>{decisivePlayer}</Grid.Col>

                    <Grid.Col span={1}>{title('Late breaking', 'most tiles placed during "Crunch Time"')}</Grid.Col>
                    <Grid.Col span={1} data-testid={'crunch_player'}>{crunchPlayer}</Grid.Col>
                </Grid>
            </Paper>
        </Center>
    </>;
}
